import { MessageDescriptor, defineMessage, defineMessages } from 'react-intl';

import { ReviewStatus, ScanLabListRoute } from './types';

export const STUDIES_MESSAGES: Record<
  ScanLabListRoute,
  Record<'title' | 'subTitle' | 'subTitleNoStatus', MessageDescriptor>
> = {
  portfolio: defineMessages({
    title: {
      id: 'scanLab.portfolioPage.title',
      defaultMessage: 'ScanLab Portfolio',
    },
    subTitle: {
      id: 'scanLab.portfolioPage.subTitle',
      defaultMessage: 'My Exams - {status}',
    },
    subTitleNoStatus: {
      id: 'scanLab.portfolioPage.subTitleNoStatus',
      defaultMessage: 'My Exam',
    },
  }),
  review: defineMessages({
    title: {
      id: 'scanLab.reviewPage.title',
      defaultMessage: 'ScanLab Review',
    },
    subTitle: {
      id: 'scanLab.reviewPage.subTitle',
      defaultMessage: 'Exams - {status}',
    },
    subTitleNoStatus: {
      id: 'scanLab.reviewPage.subTitleNoStatus',
      defaultMessage: 'Exams',
    },
  }),
};

export const STATUS_MESSAGES: Record<ReviewStatus, MessageDescriptor> = {
  unsubmitted: defineMessage({
    id: 'scanLab.status.unsubmitted',
    defaultMessage: 'Unsubmitted',
  }),
  reviewRequested: defineMessage({
    id: 'scanLab.status.reviewRequested',
    defaultMessage: 'Review Requested',
  }),
  reviewed: defineMessage({
    id: 'scanLab.status.reviewed',
    defaultMessage: 'Reviewed',
  }),
} as const;

export const INDICATORS = defineMessages({
  exams: {
    defaultMessage: 'Exams',
    id: 'scanLab.indicators.exams',
  },
  scanLabExams: {
    defaultMessage: 'ScanLab Exams',
    id: 'scanLab.indicators.scanLabExams',
  },
  examStatus: {
    defaultMessage: 'Exam Status',
    id: 'scanLab.indicators.examStatus',
  },
  examTypes: {
    defaultMessage: 'Exam Types',
    id: 'scanLab.indicators.examTypes',
  },
  submittedExams: {
    defaultMessage: 'Submitted Exams',
    id: 'scanLab.indicators.submittedExams',
  },
  unsubmittedExams: {
    defaultMessage: 'Unsubmitted Exams',
    id: 'scanLab.indicators.unsubmittedExams',
  },
  reviewed: {
    defaultMessage: 'Reviewed',
    id: 'scanLab.indicators.reviewed',
  },
  reviewedExams: {
    defaultMessage: 'Reviewed Exams',
    id: 'scanLab.indicators.reviewedExams',
  },
  allViewsCaptured: {
    defaultMessage: 'Exams with 100% of views captured',
    id: 'scanLab.indicators.allViewsCaptured',
  },
  allViews: {
    defaultMessage: '100% Views',
    id: 'scanLab.indicators.allViews',
  },
  examsLastWeek: {
    defaultMessage: 'ScanLab Exams - Last Week',
    id: 'scanLab.indicators.examsLastWeek',
  },
  vsPreviousWeek: {
    defaultMessage: 'vs. Previous Week',
    id: 'scanLab.indicators.vsPreviousWeek',
  },
  sameAsPreviousWeek: {
    defaultMessage: 'Same as Previous Week',
    id: 'scanLab.indicators.sameAsPreviousWeek',
  },
});
