import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { boolean, object, string } from 'yup';

import AuthContent from 'components/AuthContent';
import { useAuth } from 'components/AuthContext';
import AuthForm from 'components/AuthForm';
import DomainLoginPage from 'components/DomainLoginPage';
import LoginPage from 'components/LoginPage';
import type { RoutePageProps } from 'components/Route';
import accountRouteUrls from 'routes/account';
import { useConfigRoutes } from 'routes/config';

import signUpMessages from '../messages/signUp';
import CreateUserAndAcceptInvitePage from './CreateUserAndAcceptInvitePage';
import InviteEmailMismatchPage from './InviteEmailMismatchPage';
import InviteUsedPage from './InviteUsedPage';
import { AcceptInvitePageMutation } from './__generated__/AcceptInvitePageMutation.graphql';
import { AcceptInvitePage_inviteInfo$data as InviteInfo } from './__generated__/AcceptInvitePage_inviteInfo.graphql';
import { AcceptInvitePage_viewer$data as Viewer } from './__generated__/AcceptInvitePage_viewer.graphql';

const mutation = graphql`
  mutation AcceptInvitePageMutation($input: AcceptOrganizationInviteInput!) {
    acceptOrganizationInviteOrError(input: $input) {
      ... on AcceptOrganizationInvitePayload {
        viewer {
          memberships {
            __typename
          }
        }
      }
      ...RelayForm_error @relay(mask: false)
    }
  }
`;

interface Props extends RoutePageProps {
  viewer: Viewer;
  inviteInfo: InviteInfo;
}

const schema = object({
  token: string().required(),
  isOptedOutOfMarketing: boolean().nullable(),
});

function AcceptInvitePage({ match, viewer, inviteInfo, router }: Props) {
  const configRoutes = useConfigRoutes();
  const isUsed = inviteInfo.usedAt;
  const emailMismatched = inviteInfo.emailMatchesUserEmail === false;
  const isCorrectUser =
    viewer && viewer.email!.toLowerCase() === inviteInfo.email!.toLowerCase();

  const auth = useAuth();
  useEffect(() => {
    // If the Invite is used and the user is logged in
    // redirect to the home page
    if (isUsed && isCorrectUser) {
      router.replace(configRoutes.rootRoute());
    }
  }, [router, isUsed, isCorrectUser, configRoutes]);

  const handleAcceptInvite = () => {
    router.replace({
      // the slug comes from the invite info and not from the mutation payload
      // because the payload request still has the old authorization that
      // doesn't have access to the org
      pathname: accountRouteUrls.inviteAccepted({
        organizationSlug: inviteInfo.organizationInfo!.slug!,
      }),
    });
  };

  // invite email does not match user's email
  if (emailMismatched) {
    return <InviteEmailMismatchPage />;
  }

  if (!viewer) {
    if (!(match.location.state && match.location.state.willCreateAccount)) {
      // Not logged in.
      const domainProfile =
        inviteInfo.organizationInfo &&
        inviteInfo.organizationInfo.domainProfile;

      if (domainProfile) {
        // can ONLY login with SSO
        const willSignInWithSso =
          !inviteInfo.usedAt &&
          inviteInfo.organizationInfo!.domainProfile!
            .federatedIdentityProvider! &&
          !inviteInfo.organizationInfo!.domainProfile!
            .butterflyIdentityProvider;

        return (
          <DomainLoginPage
            willSignInWithSso={!!willSignInWithSso}
            inviteInfo={inviteInfo}
            domainProfile={domainProfile}
          />
        );
      }

      if (inviteInfo.userHasButterflyLogin) {
        return <LoginPage inviteInfo={inviteInfo} />;
      }
      // Login page contains logic that handles cases where the user has accepted the invite and has a login.
      // This case would only happen if the user did not have a login. This could happen if the invitation was accepted, and the user then changed the account email.
      if (inviteInfo.usedAt) return <InviteUsedPage />;
    }

    // No Butterfly account.
    return <CreateUserAndAcceptInvitePage inviteInfo={inviteInfo} />;
  }

  return !isCorrectUser ? (
    <AuthContent>
      <AuthContent.Title>
        <FormattedMessage
          id="acceptInvite.alreadyLoggedIn"
          defaultMessage="Already Logged In"
        />
      </AuthContent.Title>
      <AuthContent.Description>
        <FormattedMessage
          id="acceptInvite.confirmLogoutAndLogin"
          defaultMessage="This invitation is for <strong>{email}</strong>. You’re currently logged using a different email address ({viewerEmail}). Please log in with the corresponding email address to accept this invitation."
          values={{
            strong: (msg: string) => <strong>{msg}</strong>,
            email: inviteInfo.email,
            viewerEmail: viewer.email,
          }}
        />
      </AuthContent.Description>
      <Button
        size="lg"
        className="w-full"
        onClick={() => auth.clearAccessToken()}
      >
        <FormattedMessage
          id="acceptInvite.confirmLogout"
          defaultMessage="Log Out"
        />
      </Button>
    </AuthContent>
  ) : (
    <AuthContent>
      <AuthContent.Title>
        <FormattedMessage
          id="acceptInvite.confirmAcceptTitle"
          defaultMessage="Invitation to Join {organization}"
          values={{ organization: inviteInfo.organizationInfo!.name }}
        />
      </AuthContent.Title>
      <AuthContent.Description>
        <FormattedMessage
          id="acceptInvite.confirmAcceptBody"
          defaultMessage="You have been invited to join a new Butterfly Cloud: {organization}"
          values={{ organization: inviteInfo.organizationInfo!.name }}
        />
      </AuthContent.Description>
      <AuthForm<AcceptInvitePageMutation, typeof schema>
        schema={schema}
        mutation={mutation}
        onCompleted={handleAcceptInvite}
        defaultValue={{
          token:
            match.location.query.token || match.location.query.invite_token,
          isOptedOutOfMarketing: inviteInfo.showMarketingOptOutToggle
            ? false
            : null,
        }}
        getInput={({ isOptedOutOfMarketing }) => ({
          token:
            match.location.query.token || match.location.query.invite_token,
          isOptedOutOfMarketing,
        })}
      >
        {inviteInfo.showMarketingOptOutToggle && (
          <Form.Field
            name="isOptedOutOfMarketing"
            type="checkbox"
            className="mb-4 text-left"
          >
            <FormattedMessage {...signUpMessages.marketingOptOut} />
          </Form.Field>
        )}
        <AuthForm.Submit>
          <FormattedMessage
            id="acceptInvite.confirmAcceptButton"
            defaultMessage="Accept Invitation"
          />
        </AuthForm.Submit>
      </AuthForm>
    </AuthContent>
  );
}

export default createFragmentContainer(AcceptInvitePage, {
  inviteInfo: graphql`
    fragment AcceptInvitePage_inviteInfo on OrganizationInviteInfo {
      email
      usedAt
      userHasButterflyLogin
      emailMatchesUserEmail
      showMarketingOptOutToggle
      organizationInfo {
        name
        slug
        domainProfile {
          butterflyIdentityProvider {
            __typename
          }
          federatedIdentityProvider {
            __typename
          }
          ...DomainLoginPage_domainProfile
        }
      }
      ...CreateUserAndAcceptInvitePage_inviteInfo
      ...LoginPage_inviteInfo
      ...DomainLoginPage_inviteInfo
    }
  `,
  viewer: graphql`
    fragment AcceptInvitePage_viewer on Viewer {
      email
      ...AppPage_viewer
    }
  `,
});
