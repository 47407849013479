import Layout from '@4c/layout';
import CloseIcon from '@bfly/icons/CloseSm';
import Button from '@bfly/ui2/Button';
import DropdownList from '@bfly/ui2/DropdownList';
import OverlayTrigger, {
  OverlayTriggerMetadata,
} from '@bfly/ui2/OverlayTrigger';
import Popover, { PopoverProps, popperConfig } from '@bfly/ui2/Popover';
import { maxWidth, minWidth } from '@bfly/ui2/tailwind/screens';
import useQuery from '@bfly/ui2/useQuery';
import getNodes from '@bfly/utils/getNodes';
import useFocusManager from '@restart/hooks/useFocusManager';
import useMediaQuery from '@restart/hooks/useMediaQuery';
import useTimeout from '@restart/hooks/useTimeout';
import { stylesheet } from 'astroturf';
import clsx from 'clsx';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';
import { createFragmentContainer, graphql, useMutation } from 'react-relay';
import { DeepNonNullable } from 'utility-types';

import { ExamTagsAddTagMutation } from './__generated__/ExamTagsAddTagMutation.graphql';
import { ExamTagsQuery } from './__generated__/ExamTagsQuery.graphql';
import { ExamTagsRemoveTagMutation } from './__generated__/ExamTagsRemoveTagMutation.graphql';
import { ExamTags_study$data as Study } from './__generated__/ExamTags_study.graphql';

type StudyTag = DeepNonNullable<NonNullable<Study['tags']>>[0];

const styles = stylesheet`
  .input {
    
    & :global(.rw-widget-container) {
      @apply text-white placeholder-transparent;

      --bni-form-control-height: 2.4rem;
      background-color: transparent;
      box-shadow: none;
      align-self: center;
      height: theme('spacing.6');
      width: theme('spacing.6');
      transition: width 150ms ease-in-out;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2013%2012%22%3E%3Cpath%20d%3D%22M6.235%205.578v-4.56c0-.271.224-.5.5-.5h-.157c.27%200%20.5.223.5.5v4.56h4.562c.276%200%20.5.231.5.501v-.157c0%20.276-.23.5-.5.5H7.079v4.56c0%20.271-.224.5-.5.5h.157c-.27%200-.5-.223-.5-.5v-4.56H1.673c-.276%200-.5-.231-.5-.501v.157c0-.276.23-.5.5-.5h4.561Z%22%20stroke%3D%22%23fff%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E");
      background-position: center;
      background-size: 12px;
      background-repeat: no-repeat;
      cursor: pointer;
    }

    & :global(.rw-state-focus) :global(.rw-widget-container) {
      @apply ring placeholder-grey-25;

      background-image: none;
      width: theme('spacing.40');
      cursor: initial;
    }
  }
`;

function renderTag(tag: StudyTag, handleRemove: (tag: StudyTag) => void) {
  return (
    <Button
      key={tag.id}
      role="option"
      aria-selected
      variant="text-secondary"
      className="h-6 rounded-full bg-grey-80 px-2 inline-flex items-center font-normal"
      onClick={() => handleRemove(tag)}
    >
      <span>{tag.name}</span>
      <CloseIcon height={10} className="ml-1" />
    </Button>
  );
}

interface ExamTagsPopoverProps extends Omit<PopoverProps, 'hidden'> {
  popper: OverlayTriggerMetadata['popper'];
  tags: StudyTag[];
  onRemove: (tag: StudyTag) => void;
}

const ExamTagsPopover = forwardRef(
  (
    { popper, tags, onRemove, ...props }: ExamTagsPopoverProps,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    useEffect(() => {
      popper?.update();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tags.length]);

    return (
      <Popover {...props} ref={ref} hidden={!popper?.state} variant="dark">
        <Layout pad={1} wrap>
          {tags.map((tag) => renderTag(tag as StudyTag, onRemove))}
        </Layout>
      </Popover>
    );
  },
);
ExamTagsPopover.displayName = 'ExamTagsPopover';

const LARGE = `(max-width: ${maxWidth['xl-max'].max}) and (min-width: ${minWidth.lg})`;

interface Props {
  study: Study;
  id: string;
  className?: string;
}

function ExamTags({ study, id, className }: Props) {
  const showTimeout = useTimeout();
  const [show, setShow] = useState(false);
  const [focused, setFocused] = useState(false);

  const focushandlers = useFocusManager({
    onChange: (isFocused) => {
      setFocused(isFocused);
    },
    isDisabled: () => false,
  });

  const { data } = useQuery<ExamTagsQuery>(
    graphql`
      query ExamTagsQuery($handle: String!) {
        organization(handle: $handle) {
          studyTagConnection {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'store-and-network',
      variables: {
        handle: study.organization!.handle!,
      },
    },
  );

  const [addTag] = useMutation<ExamTagsAddTagMutation>(graphql`
    mutation ExamTagsAddTagMutation($input: AddStudyTagInput!)
    @raw_response_type {
      addStudyTag(input: $input) {
        study {
          tags {
            id
            name
          }
        }
      }
    }
  `);

  const [removeTag] = useMutation<ExamTagsRemoveTagMutation>(graphql`
    mutation ExamTagsRemoveTagMutation($input: RemoveStudyTagInput!)
    @raw_response_type {
      removeStudyTag(input: $input) {
        study {
          tags {
            id
            name
          }
        }
      }
    }
  `);

  function handleRemove(value: StudyTag) {
    removeTag({
      variables: {
        input: {
          studyId: study.id,
          tagId: value.id,
        },
      },
      optimisticResponse: {
        removeStudyTag: {
          study: {
            id: study.id,
            tags: study.tags!.filter((t) => t?.id !== value.id),
          },
        },
      },
    });
  }

  function handleChange(value: StudyTag) {
    if (!value) return;

    addTag({
      variables: {
        input: {
          studyId: study.id,
          tagId: value.id,
        },
      },
      optimisticResponse: {
        addStudyTag: {
          study: {
            id: study.id,
            tags: [value, ...study.tags!],
          },
        },
      },
    });
  }

  let overflowThreshhold = useMediaQuery(LARGE) ? 1 : 2;
  if (focused) overflowThreshhold = 0;

  const allTags = useMemo(() => {
    if (!data) return [];

    const ids = study.tags?.map((t) => t!.id) || [];

    return getNodes(data?.organization?.studyTagConnection).filter(
      (t) => !ids.includes(t.id),
    );
  }, [data, study.tags]);

  const overflowTags = study.tags?.slice(overflowThreshhold);

  return (
    <div
      role="listbox"
      id={id}
      tabIndex={-1}
      aria-multiselectable="true"
      aria-orientation="horizontal"
      data-bni-id="ExamTagsListbox"
      className={clsx('inline-flex space-x-1 max-w-full', className)}
    >
      <DropdownList
        variant="secondary"
        menuVariant="dark"
        data={allTags}
        dataKey="id"
        textField="name"
        value={null}
        filter="contains"
        data-bni-id="ExamTagsInput"
        hideSelectedOptionIndicator
        className={styles.input}
        onChange={handleChange}
        containerClassName="bg-grey-80 rounded-full"
        placeholder={defineMessage({
          id: 'examTags.placeholder',
          defaultMessage: 'Add tag',
        })}
        {...focushandlers}
      />

      {study.tags
        ?.slice(0, overflowThreshhold)
        .map((tag) => renderTag(tag as StudyTag, handleRemove))}

      {!!overflowTags?.length && (
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          show={show}
          onToggle={(nextShow) => {
            showTimeout.clear();
            setShow(nextShow);
          }}
          popperConfig={popperConfig}
          dismissOnOutsideInteraction
          renderOverlay={(overlayProps, { arrowProps, popper, toggle }) => (
            <ExamTagsPopover
              {...overlayProps}
              popper={popper}
              placement={popper!.placement}
              arrowProps={arrowProps}
              tags={overflowTags as StudyTag[]}
              onRemove={handleRemove}
              onDismiss={() => toggle(false)}
            />
          )}
        >
          <button
            type="button"
            className="h-6 rounded-full bg-grey-80 px-2 inline-flex items-center font-normal text-headline"
            data-bni-id="ExamTagsOverflowButton"
            onPointerLeave={() => {
              showTimeout.clear();
            }}
            onPointerEnter={() => {
              showTimeout.set(() => {
                setShow(true);
              }, 300);
            }}
          >
            <FormattedMessage
              id="examTags.overflowCount"
              defaultMessage="+{numRemainingTags}"
              values={{ numRemainingTags: overflowTags.length }}
            />
          </button>
        </OverlayTrigger>
      )}
    </div>
  );
}

export default createFragmentContainer(ExamTags, {
  study: graphql`
    fragment ExamTags_study on Study {
      id
      organization {
        handle
      }
      tags {
        id
        name
      }
    }
  `,
});
